<template>
    <b-modal
            id="employeeDocsModal"
            ref="modal"
            :title="userList.name+' Docs '"
            size="lg"
            centered
            hide-footer
    >
        <div class="table-responsive mb-0">
            <b-table
                    :items="userList.documents"
                    :fields="fields"
                    responsive="sm"
                    :per-page="perPage"
                    :current-page="currentPage"
                    :sort-by.sync="sortBy"
                    :sort-desc.sync="sortDesc"
                    :filter="filter"
                    :filter-included-fields="filterOn"
                    @filtered="onFiltered"
            >
                <template v-slot:cell(url)="document">
                    <i :class="checkIcon(document.item.url)" style="color: #3F83D7;font-size: 25px;cursor: pointer"
                       @click="downloadFile(document.item.url)"></i>
                </template>
                <template v-slot:cell(status)="document">
                    <docsStatus @updateStatus="updateStatus" :document="document"></docsStatus>
                </template>
                <template v-slot:cell(action)="document">
                    <b-button class="btn btn-danger btn-sm" @click="deleteDocs(document.item.id)" :disabled="document.item.status === 'Approved'"><i
                            class="fas fa-trash-alt"></i>
                    </b-button>&nbsp;
                </template>

            </b-table>
        </div>
    </b-modal>
</template>

<script>
    import {employeeMethods} from "@/state/helpers";
    import docsStatus from "./docsStatus";
    export default {
        data() {
            return {
                totalRows: 1,
                currentPage: 1,
                perPage: 25,
                filter: null,
                filterOn: [],
                sortBy: "id",
                sortDesc: true,
                fields:
                    [
                        {key: "type", label: 'Type', sortable: false},
                        {key: "url", label: 'Documents', sortable: false},
                        {key: "status", label: 'Status', sortable: false},
                        {key: "action", label: 'Action', sortable: false},
                    ]

            }
        },
        components:{docsStatus},
        props: {
            user: {
                type: Object
            }
        },
        computed:{
          userList(){
              let index = this.$store.state.employee.employees.findIndex(x=>x.id === this.user.id);
              if(index !== -1){
                  return this.$store.state.employee.employees[index];
              }
            return null
          }
        },

        methods: {
            ...employeeMethods,
            onFiltered(filteredItems) {
                this.totalRows = filteredItems.length;
                this.currentPage = 1;
            },
            downloadFile(url) {
                window.open(url)
            },
            updateDocs(documents){
                return documents.item.status
            },
            checkIcon(file){
                let fileExtension = file.split('.').pop();
                if(fileExtension === 'pdf'){
                    return 'far fa-file-pdf'
                }else if(fileExtension === 'jpeg' || fileExtension === 'png' || fileExtension === 'jpg'){
                    return 'fas fa-file-image'
                }else if(fileExtension === 'zip'){
                    return 'fas fa-file-archive'
                }else if(fileExtension === 'ppt' ){
                    return 'fas fa-file-powerpoint'
                }else if(fileExtension === 'xlsx'){
                    return 'fas fa-file-excel'
                }else if(fileExtension === 'doc' || fileExtension === 'docx'){
                    return 'fas fa-file-word'
                }else {
                    return 'fas fa-file-image'
                }
            },
            deleteDocs(id) {
                this.$swal({
                    icon: 'warning',
                    text: 'Are you sure to delete',
                    showCancelButton: true,
                    toast: true,
                    confirmButtonColor: 'green',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Yes',
                    cancelButtonText: 'No',
                }).then(result => {
                    if (result.value) {
                        this.msg = '';
                        this.deleteUserDocs({id: id}).then(res => {
                            if (res.status === 200) {
                                this.msg = res.message ? res.message : "Docs deleted successfully"
                                this.$swal({
                                    icon: 'success',
                                    text: this.msg,
                                    toast: true,
                                    showCloseButton: false,
                                    showConfirmButton: false,
                                    timer: 3000
                                });
                            }
                        }).catch(error => {
                            this.unauthorizedError(error)
                            this.msg = error ? error : "Something went wrong!!!"
                            this.$swal({
                                icon: 'error',
                                text: this.msg,
                                showCloseButton: true,
                            });
                        });
                    }
                });
            },
            unauthorizedError(err) {
                if (err.data.status === 401) {
                    return this.$router.push('/logout');
                }
            },

        }
    }
</script>