<template>
    <div class="d-flex">
            <span v-if="is_bool">{{currentStatus}}</span>
            <div v-if="!is_bool">
                <b-form-select v-model="selected_status" @change="changeStatus(document,selected_status)"
                               class="mb-3">
                    <b-form-select-option :value="getStatus" v-for="(getStatus,index) in allStatus" :key="index" >{{getStatus}}
                    </b-form-select-option>
                </b-form-select>
            </div>
        </div>
</template>

<script>
    import {employeeMethods} from "@/state/helpers";
    export default {
        data(){
            return{
                msg : '',
                is_bool : false,
                currentStatus:'',
                selected_status:null,
                allStatus: ['Pending', 'Approved', 'Not Approved'],
            }
        },
        props:{
            document:{
                type:Object
            }
        },
        methods:{
            ...employeeMethods,
            changeStatus(document, status) {
                let data = {id: document.item.id, status: status}
                this.updateStatus({data}).then(res => {
                    // this.currentStatus = res.data.status
                    this.checkingStatus(res.data.status);
                    this.msg = "Status changed successfully"
                    this.$swal({
                        icon: 'success',
                        text: this.msg,
                        toast: true,
                        showCloseButton: false,
                        showConfirmButton: false,
                        timer: 3000
                    });
                }).catch(err => {
                    this.unauthorizedError(err);
                })
            },
            unauthorizedError(err) {
                if (err.data.status === 401) {
                    return this.$router.push('/logout');
                }
            },
            checkingStatus(status){
                this.currentStatus = status
                this.is_bool = status === 'Approved';
            },
        },
        mounted() {
            this.checkingStatus(this.document.item.status);
            this.currentStatus = this.document.item.status
            this.selected_status = this.document.item.status
        }
    }
</script>